import p5 = require("p5");
import { TeamBoard } from './teamBoard';
import { setSketch, s } from './sketch';
import { Client, Room } from "colyseus.js";
import { GameState } from "../state/GameState";
import { PlayerType } from "../state/Player";
import { DOWN, LEFT, RIGHT } from "../messages/movement";
import { NOT_READY, READY } from "../messages/readystate";


document.addEventListener('DOMContentLoaded', async () => {
    var gameState: GameState | undefined;
    const port = 80; //Number(process.env.PORT || 4444);
    const host = window.document.location.host.replace(/:.*/, '');
    const client = new Client(location.protocol.replace("http", "ws") + "//" + host + (location.port ? ':' + port : ''));
    const intro = <HTMLDivElement>document.querySelector("#intro");
    const createMode = <HTMLDivElement>document.querySelector("#create");
    const joinMode = <HTMLDivElement>document.querySelector("#join");
    const name = <HTMLInputElement>document.querySelector("#playerName");
    const joinRoomID = <HTMLInputElement>document.querySelector("#joinRoomID");

    const readyButton = <HTMLDivElement>document.querySelector("#ready");

    const popup = <HTMLDivElement>document.querySelector("#popup");
    const preplay = <HTMLDivElement>document.querySelector("#preplay");
    const team0 = <HTMLDivElement>document.querySelector("#team1");
    const team1 = <HTMLDivElement>document.querySelector("#team2");
    const team2 = <HTMLDivElement>document.querySelector("#team3");
    const teamsDiv = [team0, team1, team2];
    const join1 = <HTMLInputElement>document.querySelector("#join1");
    const join2 = <HTMLInputElement>document.querySelector("#join2");
    const join3 = <HTMLInputElement>document.querySelector("#join3");

    const resultDiv = <HTMLDivElement>document.querySelector("#result");
    const notify = <HTMLDivElement>document.querySelector("#notify");
    const roomidtext = <HTMLSpanElement>document.querySelector("#roomidtext");
    const roomidicon = <HTMLLinkElement>document.querySelector("#roomidicon");

    const btn_right = <HTMLButtonElement>document.querySelector("#button-right");
    const btn_left = <HTMLButtonElement>document.querySelector("#button-left");
    const btn_up = <HTMLButtonElement>document.querySelector("#button-up");
    const btn_down = <HTMLButtonElement>document.querySelector("#button-down");
    var myTeam = 2;
    var until = 0;

    // teamModal.style.display = "none"; readyModal.style.display = "block";
    // let room : Room<GameState> | undefined;
    // createMode.addEventListener("click", async () => { client.create<GameState>("tetrolyseus").then((roomState) => { room = roomState; }); });
    const roomFunction = (room: Room<GameState>) => {
        intro.style.display = "none";
        preplay.style.display = "block";
        //        teamModal.style.display = "block";
        window.location.hash = "roomid=" + room.id;
        roomidtext.textContent = room.id;
        roomidtext.addEventListener("click", () => { navigator.clipboard.writeText(location.protocol + "//" + window.document.location.host + "#roomid="+room.id); alert ("Room URL copied") });
        roomidicon.addEventListener("click", () => { navigator.clipboard.writeText(location.protocol + "//" + window.document.location.host + "#roomid="+room.id); alert ("Room URL copied"); return false });

        const handleInput = (ev: KeyboardEvent) => {
            if (room && ev.code === "Space" || ev.code === "ArrowUp") {
                room.send("rotate", {});
            } else if (ev.code === "ArrowLeft") {
                room.send("move", LEFT);
            } else if (ev.code === "ArrowRight") {
                room.send("move", RIGHT);
            } else if (ev.code === "ArrowDown") {
                room.send("move", DOWN);
            }
        };
        room.onStateChange((newState: GameState) => {

            gameState = newState;

            var myStatus;
            for (let j = 0; j < 3; j++) {
                var temp = document.createElement("div")
                for (let i = 0; i < gameState[j].players.length; i++) {
                    var pName = gameState[j].players[i]._name;
                    var pReady = gameState[j].players[i]._ready;
                    var q1 = document.createElement("div");

                    q1.textContent = pName;

                    if (pReady) {
                        q1.classList.add("readyname");
                    }

                    //Is this player me?
                    if (gameState[j].players[i]._id == room.sessionId) {
                        myTeam = j;
                        myStatus = pReady;
                        q1.textContent += " 👤";
                    }

                    temp.appendChild(q1);
                }
                teamsDiv[j].innerHTML = temp.innerHTML;
            }

            // Am I an observer?
            if (myTeam > 1) {
                readyButton.style.display = "none";
            }
            else {
                readyButton.style.display = "block";
            }

            // Am I ready?
            if (myStatus) {
                readyButton.style.backgroundColor = "#e9ec19";
                readyButton.textContent = "Unready";
            }
            else {
                readyButton.style.backgroundColor = "#2EE59D";
                readyButton.textContent = "Ready";
            }

            // Is this my turn?
            if (gameState) {
                notify.classList.remove("blink");
                if (gameState[myTeam].running && gameState[myTeam].turn == room.sessionId) {
                    notify.textContent = "Your turn";
                    notify.style.display = "block";
                    notify.classList.add("blink");
                    notify.style.color = "rgb(112, 240, 108)";
                    if (!(typeof document.onkeydown === "function")) {
                        document.addEventListener('keydown', handleInput);
                    }
                }
                else {
                    document.removeEventListener('keydown', handleInput);
                    // Is game over?
                    if ((gameState[myTeam].result == 1) && (!gameState[myTeam].running)) {
                        notify.textContent = "Game Over";
                        notify.style.display = "block";
                        notify.style.color = "rgb(252, 217, 22)";
                    }
                    else {
                        notify.style.display = "none";
                    }
                }
            }

        });

        join1.addEventListener("click", () => { room.send("team", PlayerType.TEAM1); });
        join2.addEventListener("click", () => { room.send("team", PlayerType.TEAM2); });
        join3.addEventListener("click", () => { room.send("team", PlayerType.OBSERVER); });
        readyButton.addEventListener("click", () => { room.send("ready", READY) });
    };

    createMode.addEventListener("click", function(){createRoom(5)}, false);
    joinMode.addEventListener("click", function(){joinRoom(5)}, false);



    function createRoom(n)
    {
        if (!name || name.value === "") {
            alert("Please input your name");
            return;
        }
        else {
            client.create<GameState>("tetrolyseus", { name: name.value, privateRoom: 1 })
                .then(roomFunction)
                .catch(err => { 
                    if (n<1)
                    {
                        alert("Server busy, please try again");
                    }
                    else
                    {
                        createRoom(n-1);
                    }
                });
        }        
    }

    function joinRoom(n)
    {
        if (!name || name.value === "") {
            alert("Please input your name");
            return;
        }
        else {
            client.joinById<GameState>(joinRoomID.value, { name: name.value })
                .then(roomFunction)
                .catch(err => { 
                    if (n<1)
                    {
                        alert("Server busy, please try again");
                    }
                    else
                    {
                        joinRoom(n-1);
                    }
                });
        }        
    }


    let ip5 = new p5((sketch) => {
        setSketch(sketch);
        const windowinnerWidth = window.innerWidth - 20;
        const windowinnerHeight = window.innerHeight - 20;

        let teamBoard0: TeamBoard;
        let teamBoard1: TeamBoard;
        const sz = Math.min(windowinnerWidth / 44, windowinnerHeight / 26);
        sketch.setup = () => {
            s.createCanvas(windowinnerWidth, windowinnerHeight);
            s.frameRate(24);

            teamBoard0 = new TeamBoard(windowinnerWidth / 2 - sz * 21.1, 0, sz,21,11);
            teamBoard1 = new TeamBoard(windowinnerWidth / 2 + sz * 0.1, 0, sz,21,11);
        };
        sketch.windowResized = () => {
            s.resizeCanvas(windowinnerWidth, windowinnerHeight);
        }

        sketch.draw = () => {
            s.fill(200);
            s.textSize(0.9 * sz);
            s.textAlign(s.CENTER);
            s.text("Team 1", windowinnerWidth / 2 - sz * 15.5, sz * 1, sz * 10, sz * 3);
            s.text("Team 2", windowinnerWidth / 2 + sz * 5.5, sz * 1, sz * 10, sz * 3);

            // both are 0: not running 
            // SHOULD BE TEAMING/READY STATE 
            /*
            if (!gameState || (!gameState[0].running && gameState[1].running && gameState[0].result === 3)) {
            //    gameState && console.log(gameState[0].running, gameState[1].running, gameState[0].result, gameState[1].result)
                teamBoard0.draw();
                teamBoard1.draw();
            }
            // running stage
            else 
            */
            if (!gameState) { } else

                // If games is running
                if (gameState[0].running || gameState[1].running) {// && gameState[0].result !== 1) { // update
                    popup.style.display = 'none';
                    resultDiv.style.display = 'none';
                    // console.log(gameState.currentBlock, gameState.nextBlock);
                    teamBoard0.update(gameState[0]);
                    teamBoard1.update(gameState[1]);
                }

                // If games stopped and cooling down, showing result
                else if ((gameState[0].result === 1) && (gameState[1].result === 1)) {
                    resultDiv.innerHTML = '';
                    resultDiv.style.display = "block";
                    const resultTitle = <HTMLElement>document.createElement("p");
                    //Draw
                    if (gameState[0].totalPoints == gameState[1].totalPoints) {
                        resultTitle.textContent = "Draw";
                        resultTitle.style.color = "#fff";
                    }
                    else {
                        var team1Win = (gameState[0].totalPoints > gameState[1].totalPoints);

                        if (myTeam == 2) {
                            resultTitle.textContent = team1Win ? "Team 1 Victory" : "Team 2 Victory";
                            resultTitle.style.color = "#fff";
                        }
                        else {
                            resultTitle.textContent = ((team1Win && (myTeam == 0)) || (!team1Win && (myTeam == 1))) ? "Victory" : "Defeat";
                            resultTitle.style.color = ((team1Win && (myTeam == 0)) || (!team1Win && (myTeam == 1))) ? "#0f0" : "#f00";
                        }
                    }
                    resultTitle.setAttribute("class", "title");
                    resultDiv.append(resultTitle);
                    const team1_result = <HTMLElement>document.createElement("p");
                    team1_result.textContent = "Team 1 score: " + gameState[0].totalPoints;
                    team1_result.style.color = "#fff";
                    resultDiv.append(team1_result);
                    const team2_result = <HTMLElement>document.createElement("p");
                    team2_result.textContent = "Team 2 score: " + gameState[1].totalPoints;
                    team2_result.style.color = "#fff";
                    resultDiv.append(team2_result);
                    const countdown = <HTMLElement>document.createElement("p");
                    resultDiv.append(countdown);
                    if (until < Date.now())
                    {
                        until = Date.now() + 15*1000;
                    }
                    else
                    {
                        countdown.textContent = Math.floor( (until - Date.now())/1000).toString();
                    }
                }
                // If games stopped and restarted. Back to ready stage
                else {
                    popup.style.display = 'block';
                    resultDiv.style.display = 'none';
                }
        };
    });
});